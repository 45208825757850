import GestaoDeAulasIndexPage from "@/Pages/GestaoDeAulas/index.vue";
import NewEditGestaoDeAulaPage from "@/Pages/GestaoDeAulas/NewEditGestaoDeAulaPage.vue";
import Geral from "@/Pages/GestaoDeAulas/Geral.vue";
import NewEditGestaoDeAulaPageGeral from "@/Pages/GestaoDeAulas/NewEditGestaoDeAulaPageGeral.vue";
import PlanosBimestrais from "@/Pages/GestaoDeAulas/planosBimestrais.vue";
import PlanosMensais from "@/Pages/GestaoDeAulas/planosMensais.vue";
import PlanoMensalInfantil from "@/Pages/GestaoDeAulas/PlanoMensalInfantil.vue";
import PlanoMensalEspecial from "@/Pages/GestaoDeAulas/PlanoMensalEspecial.vue";
import Planos from "@/Pages/Planos/index.vue";
import MetricasDaTurma from "@/Pages/GestaoDeAulas/dashboard.vue";

export default [
  {
    path: "/gestoes-de-aulas/",
    name: "gestoesDeAulas",
    component: GestaoDeAulasIndexPage,
  },
  {
    path: "/gestoes-de-aulas/create",
    name: "gestoesDeAulas.create",
    component: NewEditGestaoDeAulaPage,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/edit/:gestaoDeAulasId",
    name: "gestoesDeAulas.edit",
    component: NewEditGestaoDeAulaPage,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas/geral",
    name: "gestoesDeAulas.geral",
    component: Geral,
  },
  {
    path: "/gestoes-de-aulas/create",
    name: "gestoesDeAulas.createGeral",
    component: NewEditGestaoDeAulaPageGeral,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/geral/edit/:gestaoDeAulasId",
    name: "gestoesDeAulas.editGeral",
    component: NewEditGestaoDeAulaPageGeral,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-bimestral",
    name: "gestoesDeAulas.planoBimestralCreate",
    component: PlanosBimestrais,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-mensal/:disciplinaId",
    name: "gestoesDeAulas.newEditplanoMensal",
    component: PlanosMensais,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-mensal-infantil",
    name: "gestoesDeAulas.newEditplanoMensalInfantil",
    component: PlanoMensalInfantil,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/:matriculaId/:disciplinaId/plano-mensal-especial",
    name: "gestoesDeAulas.newEditplanoMensalEspecial",
    component: PlanoMensalEspecial,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/:id/turma/metricas/:gestao",
    name: "gestoesDeAulas.metricasTurma",
    component: MetricasDaTurma,
  },
  // {
  //   path: "/gestoes-de-aulas/planos",
  //   name: "gestoesDeAulas.planos",
  //   component: Planos,
  // },
];
