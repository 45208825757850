import Axios from "@/plugins/Axios";
import Plano from "../Models/Plano";

export class PlanosBimestraisService {
  async planoBimestral(gestaoDeAulaId, bimestre) {
    const response = await Axios().get(
      `planos-bimestrais/plano-bimestral/${gestaoDeAulaId}/${bimestre}`
    );
    return response.data;
  }

  async planoMensal(gestaoDeAulaId, mes, periodo, disciplinaId) {
    const response = await Axios().get(
      `planos-bimestrais/plano-mensal/${gestaoDeAulaId}/${mes}/${periodo}/${disciplinaId}`
    );
    if (response.data?.plano) {
      const status = new Plano(response.data?.plano);
      response.data.plano = { ...status };
    }
    return response;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post("planos-bimestrais/criar-plano-bimestral", {
      plano,
    });
    return response.data;
  }

  async planoMensalCreateOrUpdate(plano) {
    const response = await Axios().post("planos-bimestrais/plano-mensal/create-or-update", {
      plano,
    });
    return response.data;
  }
}
export default new PlanosBimestraisService();
