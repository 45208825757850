<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeAulas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Vínculo
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Professor" rules="required" v-slot="{ errors }">
              <e-label>Professor</e-label>
              <e-autocomplete
                :items="professoresList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => `${val.cpf} - ${val.nome}`"
                :error-messages="errors"
                :loading="carregandoProfessores"
                v-model="form.instrutor_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row
          class="animate__animated animate__zoomIn"
          style="animation-duration: 0.5s"
          v-for="relacao of relacoes"
          :key="relacoes.indexOf(relacao)"
        >
          <v-col class="pt-0 pb-0" cols="12" sm="6" md="3">
            <ValidationProvider name="Turma" rules="required" v-slot="{ errors }">
              <e-label>Turma</e-label>
              <e-autocomplete
                @change="loadRotinaTurma(relacao.turma_id, relacoes.indexOf(relacao))"
                :items="turmasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoTurmas"
                v-model="relacao.turma_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="2" v-if="user.ano.id >= 5">
            <v-container class="px-0" fluid>
              <v-switch
                v-model="relacao.is_polivalencia"
                :disabled="editing"
                :label="`Polivalência`"
                inset
              ></v-switch>
            </v-container>
          </v-col>
          <v-col class="pt-0 pb-0" cols="9" sm="6" md="3">
            <ValidationProvider
              name="Disciplina"
              rules="required"
              v-slot="{ errors }"
              v-show="!relacao.is_polivalencia"
            >
              <e-label>Disciplina</e-label>
              <v-select
                :items="disciplinasList"
                :item-value="(item) => item.id"
                :item-text="(item) => item.descricao"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoDisciplinas"
                v-model="relacao.disciplina_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
                clearable
                single-line
              >
                <template v-slot:item="{ item }">
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox v-model="relacao.disciplina_id" :value="item.id"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.descricao }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </ValidationProvider>

            <ValidationProvider v-show="relacao.is_polivalencia" name="Disciplina">
              <e-label>Disciplinas</e-label>
              <v-autocomplete
                v-model="relacao.disciplinas_polivalencia"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :items="disciplinasList"
                v-show="!editing"
                chips
                label="Selecione as Disciplinas"
                multiple
                solo
              ></v-autocomplete>
              <div v-show="editing" v-for="(item, index) in relacao.disciplinas" :key="index">
                <v-chip x-small>{{ item.descricao }}</v-chip>
              </div>
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <e-label>CH</e-label>
                <v-text-field v-bind="attrs" v-on="on" v-model="relacao.carga_horaria" dense solo />
              </template>
              <span>Carga horária do professor</span>
            </v-tooltip>
          </v-col>
          <template v-if="turmaSelecionada">
            <v-btn
              v-show="relacao.turma_id"
              class="mt-7 ml-3 success"
              @click="
                {
                  getHorarios(relacao.turma_id, relacoes.indexOf(relacao));
                }
              "
              >Definir/Editar Momento</v-btn
            >
          </template>
          <template v-else>
            <v-alert color="warning" icon="fa fa-frown-o"
              >Infelizmente você não cadastrou uma rotina para a turma dessa relação, para definir
              os momentos você precisa ir em Turmas e Cadastrar a Rotina.</v-alert
            >
          </template>
          <v-col class="pt-0 pb-0" cols="1">
            <v-btn
              v-if="relacoes.length > 1"
              class="mt-8"
              color="error"
              @click="() => (relacoes = relacoes.filter((relacaoObj) => relacaoObj !== relacao))"
              small
              :disabled="submittingForm"
              ><v-icon small>fa-trash</v-icon></v-btn
            >
          </v-col>

          <v-bottom-sheet
            v-if="addHorario === relacoes.indexOf(relacao) + 1"
            v-model="addHorario"
            scrollable
          >
            <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
              <v-btn color="error" dark small @click="addHorario = false"> Fechar </v-btn>
              <h2 class="mt-4">Selecione os Momentos</h2>

              <v-card>
                <v-card-text class="scrollable-content">
                  <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
                    <template>
                      <thead style="background-color: #e0e0e0">
                        <tr>
                          <th class="text-left">SEG</th>
                          <th class="text-left">TER</th>
                          <th class="text-left">QUA</th>
                          <th class="text-left">QUI</th>
                          <th class="text-left">SEX</th>
                        </tr>
                      </thead>
                      <tbody class="text-left">
                        <tr>
                          <td>
                            <template>
                              <v-treeview
                                open-all
                                selectable
                                item-disabled="locked"
                                :items="itensHorariosDisponiveisSeg[relacoes.indexOf(relacao)]"
                                v-model="relacao.horario_segunda"
                              ></v-treeview>
                            </template>
                          </td>
                          <td>
                            <template>
                              <v-treeview
                                open-all
                                selectable
                                item-disabled="locked"
                                :items="itensHorariosDisponiveisTer[relacoes.indexOf(relacao)]"
                                v-model="relacao.horario_terca"
                              ></v-treeview>
                            </template>
                          </td>
                          <td>
                            <template>
                              <v-treeview
                                open-all
                                selectable
                                item-disabled="locked"
                                :items="itensHorariosDisponiveisQua[relacoes.indexOf(relacao)]"
                                v-model="relacao.horario_quarta"
                              ></v-treeview>
                            </template>
                          </td>
                          <td>
                            <template>
                              <v-treeview
                                open-all
                                selectable
                                item-disabled="locked"
                                :items="itensHorariosDisponiveisQui[relacoes.indexOf(relacao)]"
                                v-model="relacao.horario_quinta"
                              ></v-treeview>
                            </template>
                          </td>
                          <td>
                            <template>
                              <v-treeview
                                open-all
                                selectable
                                item-disabled="locked"
                                :items="itensHorariosDisponiveisSex[relacoes.indexOf(relacao)]"
                                v-model="relacao.horario_sexta"
                              ></v-treeview>
                            </template>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-sheet>
          </v-bottom-sheet>
        </v-row>
        <v-row>
          <v-col v-if="mensagemErro" cols="12">
            <div>
              <v-alert border="bottom" type="error">{{ mensagemErro }} </v-alert>
            </div>
          </v-col>
          <v-col v-else cols="12" class="d-flex justify-space-between">
            <div class="d-flex">
              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn
                class="ml-3"
                v-if="!editing"
                color="secondary"
                @click="adicionarRelacao"
                :disabled="submittingForm"
              >
                Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    relacoes: {
      handler() {
        this.impedimentoPolivalencia();
      },
      deep: true,
    },
  },

  mounted() {
    this.loadData();
  },
  data() {
    return {
      addHorario: false,
      submittingForm: false,
      professoresList: [],
      turmasList: [],
      form: {},
      aulaSelecionada: {},
      relacoes: [{}],
      disciplinasList: [],
      carregandoProfessores: false,
      carregandoTurmas: false,
      carregandoDisciplinas: false,
      itensHorariosDisponiveisSeg: [],
      itensHorariosDisponiveisTer: [],
      itensHorariosDisponiveisQua: [],
      itensHorariosDisponiveisQui: [],
      itensHorariosDisponiveisSex: [],
      rotina: [],
      turmaSelecionada: false,
      isPolivalencia: false,
      disciplinasPolivalencia: [""],
      mensagemErro: "",
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    getHorarios(turmaId, indexRelacao) {
      this.loadRotinaTurma(turmaId, indexRelacao, true);
    },
    async loadRotinaTurma(id, indexRelacao, dentro = false) {
      this.turmaSelecionada = true;
      const rotina = await this.$services.turmasService.visualizarTurma(id);
      this.rotina = JSON.parse(rotina.turma.rotina)[0];

      // segunda-feira
      const arrayNovoSeg = [];

      this.rotina.seg.map((idSelecionado) => {
        return this.$constants.rotinasTurma.map((item) => {
          return item.children.map((subItem) => {
            if (subItem.id === idSelecionado) {
              arrayNovoSeg.push(subItem);
            }
          });
        });
      });

      const rotinaNovaSelecionadaSegunda = [];
      arrayNovoSeg.map((item) => {
        const search = rotinaNovaSelecionadaSegunda.find((subItem) => subItem.id === item.pai);
        if (search) {
          search.children.push(item);
        } else {
          const getName = this.$constants.rotinasTurma.filter((rotina) => rotina.id === item.pai)[0]
            .name;
          const itemNovo = {
            id: item.pai,
            name: getName,
            children: [],
          };
          rotinaNovaSelecionadaSegunda.push(itemNovo);
          const search = rotinaNovaSelecionadaSegunda.find((subItem) => subItem.id === item.pai);
          search.children.push(item);
        }
      });

      this.itensHorariosDisponiveisSeg[indexRelacao] = [];
      this.itensHorariosDisponiveisSeg[indexRelacao] = rotinaNovaSelecionadaSegunda;

      // terca-feira
      const arrayNovoTer = [];

      this.rotina.ter.map((idSelecionado) => {
        return this.$constants.rotinasTurma.map((item) => {
          return item.children.map((subItem) => {
            if (subItem.id === idSelecionado) {
              arrayNovoTer.push(subItem);
            }
          });
        });
      });

      const rotinaNovaSelecionadaTerca = [];
      arrayNovoTer.map((item) => {
        const search = rotinaNovaSelecionadaTerca.find((subItem) => subItem.id === item.pai);
        if (search) {
          search.children.push(item);
        } else {
          const getName = this.$constants.rotinasTurma.filter((rotina) => rotina.id === item.pai)[0]
            .name;
          const itemNovo = {
            id: item.pai,
            name: getName,
            children: [],
          };
          rotinaNovaSelecionadaTerca.push(itemNovo);
          const search = rotinaNovaSelecionadaTerca.find((subItem) => subItem.id === item.pai);
          search.children.push(item);
        }
      });

      this.itensHorariosDisponiveisTer[indexRelacao] = [];
      this.itensHorariosDisponiveisTer[indexRelacao] = rotinaNovaSelecionadaTerca;

      // quarta-feira
      const arrayNovoQua = [];

      this.rotina.qua.map((idSelecionado) => {
        return this.$constants.rotinasTurma.map((item) => {
          return item.children.map((subItem) => {
            if (subItem.id === idSelecionado) {
              arrayNovoQua.push(subItem);
            }
          });
        });
      });

      const rotinaNovaSelecionadaQuarta = [];
      arrayNovoQua.map((item) => {
        const search = rotinaNovaSelecionadaQuarta.find((subItem) => subItem.id === item.pai);
        if (search) {
          search.children.push(item);
        } else {
          const getName = this.$constants.rotinasTurma.filter((rotina) => rotina.id === item.pai)[0]
            .name;
          const itemNovo = {
            id: item.pai,
            name: getName,
            children: [],
          };
          rotinaNovaSelecionadaQuarta.push(itemNovo);
          const search = rotinaNovaSelecionadaQuarta.find((subItem) => subItem.id === item.pai);
          search.children.push(item);
        }
      });

      this.itensHorariosDisponiveisQua[indexRelacao] = [];
      this.itensHorariosDisponiveisQua[indexRelacao] = rotinaNovaSelecionadaQuarta;

      // quinta-feira
      const arrayNovoQui = [];

      this.rotina.qui.map((idSelecionado) => {
        return this.$constants.rotinasTurma.map((item) => {
          return item.children.map((subItem) => {
            if (subItem.id === idSelecionado) {
              arrayNovoQui.push(subItem);
            }
          });
        });
      });

      const rotinaNovaSelecionadaQuinta = [];
      arrayNovoQui.map((item) => {
        const search = rotinaNovaSelecionadaQuinta.find((subItem) => subItem.id === item.pai);
        if (search) {
          search.children.push(item);
        } else {
          const getName = this.$constants.rotinasTurma.filter((rotina) => rotina.id === item.pai)[0]
            .name;
          const itemNovo = {
            id: item.pai,
            name: getName,
            children: [],
          };
          rotinaNovaSelecionadaQuinta.push(itemNovo);
          const search = rotinaNovaSelecionadaQuinta.find((subItem) => subItem.id === item.pai);
          search.children.push(item);
        }
      });

      this.itensHorariosDisponiveisQui[indexRelacao] = [];
      this.itensHorariosDisponiveisQui[indexRelacao] = rotinaNovaSelecionadaQuinta;

      // sexta-feira
      const arrayNovoSex = [];

      this.rotina.sex.map((idSelecionado) => {
        return this.$constants.rotinasTurma.map((item) => {
          return item.children.map((subItem) => {
            if (subItem.id === idSelecionado) {
              arrayNovoSex.push(subItem);
            }
          });
        });
      });

      const rotinaNovaSelecionadaSexta = [];
      arrayNovoSex.map((item) => {
        const search = rotinaNovaSelecionadaSexta.find((subItem) => subItem.id === item.pai);
        if (search) {
          search.children.push(item);
        } else {
          const getName = this.$constants.rotinasTurma.filter((rotina) => rotina.id === item.pai)[0]
            .name;
          const itemNovo = {
            id: item.pai,
            name: getName,
            children: [],
          };
          rotinaNovaSelecionadaSexta.push(itemNovo);
          const search = rotinaNovaSelecionadaSexta.find((subItem) => subItem.id === item.pai);
          search.children.push(item);
        }
      });

      this.itensHorariosDisponiveisSex[indexRelacao] = [];
      this.itensHorariosDisponiveisSex[indexRelacao] = rotinaNovaSelecionadaSexta;

      if (dentro) {
        this.addHorario = indexRelacao + 1;
      }
    },
    adicionarRelacao() {
      const ultimaRelacao = this.relacoes[this.relacoes.length - 1];
      this.relacoes.push({ ...ultimaRelacao });
    },
    async submitForm() {
      this.submittingForm = true;
      try {
        if (this.editing) {
          const formVinculo = {
            ...this.relacoes[0],
            instrutor_id: this.form.instrutor_id,
          };
          await this.$services.gestoesDeAulasService.atualizar(formVinculo);
        } else {
          const formRelacoes = this.relacoes.map((relacao) => ({
            ...relacao,
            instrutor_id: this.form.instrutor_id,
            is_polivalencia: relacao.is_polivalencia ? relacao.is_polivalencia : 0,
          }));
          const responseData = await this.$services.gestoesDeAulasService.criar(formRelacoes);
          this.relacoes = responseData.filter((item) => item.errors);
        }
        this.$toast.success("Vínculos criados/atualizados com sucesso");
        if (this.relacoes.length === 0 || this.editing) {
          this.$router.push({ name: "gestoesDeAulas" });
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        this.professoresList = await this.$services.professoresService.syncAll();
        this.turmasList = await this.$services.turmasService.syncAll();
        this.disciplinasList = await this.$services.disciplinasService.syncAll();

        if (this.editing) {
          const vinculo = await this.$services.gestoesDeAulasService.gestaoGeralId(
            parseInt(this.$route.params.gestaoDeAulasId, 10)
          );

          if (vinculo) {
            this.form.instrutor_id = vinculo.instrutor_id;
            this.relacoes = [vinculo];

            // console.log("edit: ", this.relacoes[0]);
            // preencher momentos
            if (vinculo.turma.rotina != null) {
              this.turmaSelecionada = true;
              if (this.relacoes[0].momentos) {
                const rotinas = JSON.parse(this.relacoes[0].momentos);

                this.relacoes[0].horario_segunda = rotinas.segunda;
                this.relacoes[0].horario_terca = rotinas.terca;
                this.relacoes[0].horario_quarta = rotinas.quarta;
                this.relacoes[0].horario_quinta = rotinas.quinta;
                this.relacoes[0].horario_sexta = rotinas.sexta;
              }
            }
          }
        }
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
    impedimentoPolivalencia() {
      this.mensagemErro = "";
      this.relacoes.forEach((relacao) => {
        if (relacao.is_polivalencia) {
          this.isPolivalencia = true;
          this.disciplinasPolivalencia = relacao.disciplinas_polivalencia;
          if (this.disciplinasPolivalencia.length === 1) {
            this.mensagemErro =
              "Não é possível adicionar apenas uma disciplina quando Polivalência está selecionada.";
          }
        } else {
          this.isPolivalencia = false;
          this.disciplinasPolivalencia = [];
        }
      });
    },
  },
};
</script>

<style>
.scrollable-content {
  max-height: 500px; /* Defina a altura máxima para ativar a rolagem */
  overflow-y: auto; /* Adicione uma barra de rolagem vertical conforme necessário */
}
</style>
