<template>
  <div>
    <div v-for="(item, index) in filteredSistemaBNCC" :key="item.id" class="grafico-item">
      <h2 class="text-center">
        {{
          `9.${index + 1} - OBSERVAÇÃO DO CAMPO DE EXPERIÊNCIAS ${item.descricao} - ${getSigla(
            item.descricao
          )}`
        }}
      </h2>
      <canvas :ref="'grafico-' + index"></canvas>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

export default {
  props: {
    filteredSistemaBNCC: {
      type: Array,
      required: true,
    },
    notas: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.filteredSistemaBNCC.forEach((parent, index) => {
      this.renderChart(parent.children, index);
    });
  },

  methods: {
    renderChart(children, index) {
      const canvasRef = this.$refs[`grafico-${index}`];
      if (!canvasRef) return;

      const labels = ["1º PERÍODO", "2º PERÍODO", "3º PERÍODO", "4º PERÍODO"];
      const niveisDesempenho = ["ND", "ED", "D", "R", "B", "MB"];

      // Paleta de cores para as linhas
      const colors = [
        "rgba(255, 99, 132, 1)", // Vermelho
        "rgba(54, 162, 235, 1)", // Azul
        "rgba(255, 206, 86, 1)", // Amarelo
        "rgba(75, 192, 192, 1)", // Verde
        "rgba(153, 102, 255, 1)", // Roxo
        "rgba(255, 159, 64, 1)", // Laranja
        "rgba(199, 199, 199, 1)", // Cinza
        "rgba(83, 102, 255, 1)", // Azul escuro
        "rgba(47, 79, 79, 1)", // Verde escuro
      ];
      const datasets = children.map((child, childIndex) => {
        const color = colors[childIndex % colors.length];

        return {
          label: child.descricao,
          data: [
            this.mapNota(this.notas[child.id]?.nota_p1),
            this.mapNota(this.notas[child.id]?.nota_p2),
            this.mapNota(this.notas[child.id]?.nota_p3),
            this.mapNota(this.notas[child.id]?.nota_p4),
          ],
          borderColor: color,
          backgroundColor: "rgba(0, 0, 0, 0)",
          pointStyle: "circle",
          pointRadius: 6,
          borderWidth: 2,
          clip: { left: 10, top: false, right: 10, bottom: false }, // Garante que as bordas fiquem dentro do gráfico
        };
      });

      new Chart(canvasRef, {
        type: "line",
        data: {
          labels,
          datasets,
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: "Evolução das Habilidades",
            },
          },
          scales: {
            y: {
              type: "linear",
              beginAtZero: true,
              suggestedMax: 5, // Garante que o limite máximo seja controlado
              ticks: {
                stepSize: 1,
                padding: 10, // Ajusta o espaçamento interno para evitar cortes
                callback(value) {
                  return niveisDesempenho[value];
                },
              },
              title: {
                display: true,
                text: "Níveis de Desempenho",
              },
            },
            x: {
              title: {
                display: true,
                text: "Períodos",
              },
            },
          },
        },
      });
    },

    mapNota(nota) {
      const mapa = { ND: 0, ED: 1, D: 2, R: 3, B: 4, MB: 5 };
      return mapa[nota] ?? null;
    },
    getSigla(descricao) {
      const siglas = {
        "CAMPO DE EXPERIÊNCIAS “O EU, O OUTRO E O NÓS”": "EON",
        "CAMPO DE EXPERIÊNCIAS “CORPO, GESTOS E MOVIMENTOS”": "CGM",
        "CAMPO DE EXPERIÊNCIAS “TRAÇOS, SONS, CORES E FORMAS”": "TSCF",
        "CAMPO DE EXPERIÊNCIAS “ESCUTA, FALA, PENSAMENTO E IMAGINAÇÃO”": "EFPI",
        "A CAMPO DE EXPERIÊNCIAS “ESPAÇOS, TEMPOS, QUANTIDADES, RELAÇÕES E TRANSFORMAÇÕES”":
          "ETQRT",
      };

      return siglas[descricao] || "";
    },
  },
};
</script>

<style scoped>
.grafico-item {
  margin-bottom: 30px;
}
</style>
