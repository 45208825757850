import RelatorioListarTurmaPage from "@/Pages/Relatorios/ListarTurmaPage.vue";
import lancarRelatorioBNCCPage from "@/Pages/Relatorios/lancarRelatorioBNCCPage.vue";
import relatorioPage from "@/Pages/Relatorios/index.vue";

import lancarRelatorioAnualPage from "@/Pages/Relatorios/lancarRelatorioAnualPage.vue";

export default [
  {
    path: "/relatorios/:franquiaId",
    name: "relatorios",
    component: relatorioPage,
  },
  {
    path: "/relatorios/visualizar/:gestaoDeAulasId/:turmaId",
    name: "relatorios.visualizar",
    component: RelatorioListarTurmaPage,
  },
  {
    path: "/relatorios/lancarRelatorioBNCC/:matricula_id",
    name: "relatorios.lancarRelatorioBNCC",
    component: lancarRelatorioBNCCPage,
  },

  {
    path: "/relatorios/lancarRelatorioAnual/:matricula_id",
    name: "relatorios.lancarRelatorioAnual",
    component: lancarRelatorioAnualPage,
  },
];
